// export const IsNumber = (event) => {
//     const allowedKeys = ["Backspace", "Enter", " ", "Tab"];
//     const isNumeric =
//         (event.key >= "0" && event.key <= "9") ||
//         (event.key >= "NumPad0" && event.key <= "NumPad9");

//     // Allow copy and paste
//     if (event.ctrlKey || event.metaKey) {
//         return;
//     }

//     if (!allowedKeys.includes(event.key) && !isNumeric) {
//         event.preventDefault();
//     }
// };


export const IsNumber = (event) => {
    const allowedKeys = ["Backspace", "Enter", " ", "Tab"];
    const isNumeric =
        (event.key >= "0" && event.key <= "9") ||
        (event.key >= "NumPad0" && event.key <= "NumPad9");

    // Allow copy and paste
    if (event.ctrlKey || event.metaKey) {
        if (event.type === "paste") {
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedValue = clipboardData.getData("text");
            if (!isNumericString(pastedValue)) {
                event.preventDefault();
                return;
            }
        } else {
            return;
        }
    }

    if (!allowedKeys.includes(event.key) && !isNumeric) {
        event.preventDefault();
    }
};

const isNumericString = (str) => {
    return /^\d+$/.test(str);
};


export function getPincode(returnDefault = true) {
    const pincode = localStorage.getItem("m_userPincode");
    if (pincode || !returnDefault) return pincode;
  
    const DEFAULT_PINCODE = window?.FPI?.state?.theme?.globalConfig?._data?.props?.defaultPincode;
    localStorage.setItem("m_userPincode", DEFAULT_PINCODE);
    return DEFAULT_PINCODE;
  }
