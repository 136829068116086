const successIcon = `
  <svg class="icon success" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" fill="#81BC89"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.1237 15.246C10.9327 15.246 10.7397 15.173 10.5938 15.026L8.21975 12.653C7.92675 12.36 7.92675 11.886 8.21975 11.593C8.51275 11.3 8.98675 11.3 9.27975 11.593L11.1237 13.435L15.3397 9.21999C15.6328 8.92699 16.1067 8.92699 16.3997 9.21999C16.6927 9.51299 16.6927 9.98699 16.3997 10.28L11.6537 15.026C11.5078 15.173 11.3157 15.246 11.1237 15.246Z" fill="white"/>
  </svg>
`;

const errorIcon = `
  <svg class="icon error" width="24" height="24" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="7" fill="#EB5757"/>
    <path d="M7 3.75L7 7.75" stroke="white" stroke-width="1.75" stroke-linecap="round"/>
    <circle cx="7.02539" cy="10.125" r="0.875" fill="white"/>
</svg>
`;

const infoIcon = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1649_1662)">
    <circle cx="12" cy="12" r="10" fill="#008945"/>
    <path d="M7.5 12.2126L10.1108 14.8233C10.267 14.9795 10.5203 14.9795 10.6765 14.8233L16.5 9.00018" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
    <g clip-path="url(#clip1_1649_1662)">
    <circle cx="12" cy="11.7168" r="10" fill="#008945"/>
    <path d="M7.5 11.9294L10.1108 14.5401C10.267 14.6963 10.5203 14.6963 10.6765 14.5401L16.5 8.71697" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
    <circle cx="12" cy="11.5713" r="10" fill="#EAC645"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0668 5.87158C12.3982 5.87158 12.6668 6.14021 12.6668 6.47158V13.4716C12.6668 13.803 12.3982 14.0716 12.0668 14.0716C11.7354 14.0716 11.4668 13.803 11.4668 13.4716L11.4668 6.47158C11.4668 6.14021 11.7354 5.87158 12.0668 5.87158Z" fill="white"/>
    <path d="M12.6668 16.0717C12.6668 16.4031 12.3982 16.6717 12.0668 16.6717C11.7354 16.6717 11.4668 16.4031 11.4668 16.0717C11.4668 15.7403 11.7354 15.4717 12.0668 15.4717C12.3982 15.4717 12.6668 15.7403 12.6668 16.0717Z" fill="white"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_1649_1662">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    <clipPath id="clip1_1649_1662">
    <rect width="24" height="24" fill="white" transform="translate(0 -0.283203)"/>
    </clipPath>
    </defs>
  </svg>
`;

const defaultOptions = {
  linkLabel: "",
  linkUrl: "",
  linkCallback: null,
  duration: 3000,
  icon: successIcon,
  type: "success",
};

export default {
  methods: {
    showSuccessToast(message, options) {
      this.showToast(message, { ...options, icon: successIcon, type: "success" });
    },
    showErrorToast(message, options) {
      this.showToast(message, { ...options, icon: errorIcon, type: "error" });
    },
    showInfoToast(message, options) {
      this.showToast(message, { ...options, icon: infoIcon, type: "info" });
    },
    showToast(message, options) {
      let customClass = "";
      if (this.$route.path === "/c/offline-order-details") {
        customClass = "toast-offline-order-history-details";
      }
      options = { ...defaultOptions, ...options };
      const action = options.linkLabel
        ? {
            text: options.linkLabel || "Close",
            class: "link-label",
            onClick: (e, toastObject) => {
              e.stopPropagation();
              e.preventDefault();
              toastObject.goAway(0);
              if (options.linkCallback) options.linkCallback(e, toastObject);
              else if (options.linkUrl) this.$router.push(options.linkUrl);
            },
          }
        : {};
      this.$toasted?.clear();
      this.$toasted?.[options.type]?.(
        `
        ${options.icon}
        <div class="toast-message text-line-clamp">${message}</div>
      `,
        {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: options.duration,
          containerClass: customClass,
          action: action,
        }
      );
    },
  },
};
