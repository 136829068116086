import { isBrowser } from "browser-or-node";

export const CUSTOM_EVENTS = {
  ADD_GIFT_CARD: "custom.add_gift_card",
  GIFT_CARD_INFO: "custom.gift_card_info",
  GIFT_CARD_DETAIL_ADDED: "custom.gift_card_detail_added",
  GIFT_CARD_STATUS_ADD: "custom.gift_card_status_add",
  GIFT_CARD_APPLIED: "custom.gift_card_applied",
  GIFT_CARD_STATUS_APPLY: "custom.gift_card_status_apply",
  GIFT_CARD_REMOVED: "custom.gift_card_removed",
};

export const emitEvent = (eventName, eventData) => {
  if (!eventName) return;
  const source = getSource();
  window?.FPI?.event?.emit(eventName, { source, ...eventData });
};


export const getSource = () => {
    return (isBrowser && window?.__application) || "web";
  };